"use client";

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import sal from "sal.js";
import { Separator } from "../Separator";

import { AdvantageItems } from "./AdvantageItems";

const ADVANTAGE_ITEMS = [
  {
    title: "Performance",
    desc: "Better throughput and payment finality than available solutions.",
    icon: "activity",
    delay: 100,
  },
  {
    title: "Scalable",
    desc: "Can easily handle 2x the traffic of credit-card networks.",
    icon: "shuffle",
    delay: 100,
  },
  {
    title: "Energy Efficent",
    desc: "Highly competitive fees and costs for users and service providers.",
    icon: "trending-down",
    delay: 100,
  },
  {
    title: "Sustainable",
    desc: "Ecological footprint comparable to several Google searches.",
    icon: "package",
    delay: 100,
  },
];

const Advantage = () => {
  useEffect(() => {
    sal();
  }, []);
  return (
    <>
      <div className="rainbow-callto-action-area mt--100">
        <div className="wrapper">
          <div className="rainbow-callto-action clltoaction-style-default rainbow-section-gap">
            <div className="container">
              <div className="row row--0">
                <div className="col-lg-12">
                  <div className="align-items-center content-wrapper">
                    <div className="inner">
                      <div className="content text-center">
                        <h2
                          className="title"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="200"
                        >
                          Our UPCX Advantages
                        </h2>
                        <p
                          className="description"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="300"
                        >
                          We offer 4 types of advantages to the UPCX users
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fancy-genearate-section mt--0">
            <div className="container">
              <div className="genarator-section">
                <ul className="genarator-card-group full-width-list">
                  {ADVANTAGE_ITEMS.map((data, index) => (
                    <li key={index}>
                      <Link
                        to="#"
                        className="genarator-card bg-flashlight-static center-align pb--0"
                      >
                        <div className="inner">
                          <div className="left-align">
                            <div
                              className="img-bar"
                              style={{ fontSize: "50px" }}
                            >
                              <i className={`feather-${data.icon}`}></i>
                            </div>
                            <h5 className="title mb--10">{data.title}</h5>
                            <p className="text-center">{data.desc}</p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Separator top={true} />
      </div>
    </>
  );
};

export default Advantage;
