import Advantage from "../component/Home/Advantage";
import { Blockchain } from "../component/Home/Blockchain";
import BlockchainCards from "../component/Home/BlockchainCards";
import FAQ from "../component/Home/FAQ";
import { Hero } from "../component/Home/Hero";
import Payment from "../component/Home/Payment";
import { Technology } from "../component/Home/Technology";
import { Separator } from "../component/Separator";

export const Home = () => {
  return (
    <>
      <Hero />
      <Payment />
      {/* <Blockchain /> */}
      <Separator top={false} />
      <Technology />
      <Separator top={true} />
      <BlockchainCards />
      <Separator top={false} />
      <Advantage />
      <FAQ />
    </>
  );
};
