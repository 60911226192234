import USEABILITY from "../../asset/images/solution/useability.png";

export const Useability = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Useability
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      We believe that in order to increase the use of
                      blockchain-based payments, accessibility is a key feature,
                      allowing people of all technical literacy levels to start
                      easily.
                      <br />
                      <br />
                      User interfaces of existing blockchain-based wallet apps
                      are usually more difficult to understand than existing
                      payment apps, and functions like a Block Explorer, which
                      are indispensable for interacting with blockchains,
                      contain many difficult concepts that are hard to
                      understand for general users.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={USEABILITY}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
