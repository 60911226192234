import separatorTop from "../asset/images/separator/separator-top.svg";
import separatorBottom from "../asset/images/separator/separator-bottom.svg";

type Props = {
  top: boolean;
};

export const Separator = ({ top }: Props) => {
  return (
    <>
      {top ? (
        <div className="chatenai-separator mt--40">
          <img className="w-100" src={separatorTop} alt="" />
        </div>
      ) : (
        <div className="chatenai-separator">
          <img className="w-100" src={separatorBottom} alt="" />
        </div>
      )}
    </>
  );
};
