import { Blind } from "../component/Functions/Blind";
import { Limitless } from "../component/Functions/Limitless";
import { MobilePayment } from "../component/Functions/MobilePayment";
import { MultiAssetTransfer } from "../component/Functions/MultiAssetTransfer";
import { NamedAccount } from "../component/Functions/NamedAccount";
import { PriceOracle } from "../component/Functions/PriceOracle";
import { Referral } from "../component/Functions/Referral";

export const Functions = () => {
  return (
    <>
      <MultiAssetTransfer />
      <MobilePayment />
      <NamedAccount />
      <Blind />
      <Referral />
      <Limitless />
      <PriceOracle />
    </>
  );
};
