import USER_EXPERIENCE from "../../asset/images/solution/user_experience.png";

export const UserExperience = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      User Experience
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      The payment operation should be as simple as tapping the
                      smartphone several times.
                      <br />
                      <br />
                      In many existing blockchain-based services, users can make
                      payments by scanning the recipient's QR code, but
                      merchants that have implemented custom POS systems also
                      require the possibility to make pull payments, by for
                      example scanning a bar code.
                      <br />
                      <br />
                      In addition, in order to promote payments at unmanned
                      payment terminals such as vending machines and
                      transportation facilities, support for NFC (Tap to Pay)
                      would also improve the convenience of blockchain-based
                      payment systems.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={USER_EXPERIENCE}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
