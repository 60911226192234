import SCHEDULE_PAYMENT from "../../asset/images/smartcontract/schedule.png";

export const SchedulePayment = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Scheduled Payments
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Scheduled payments are predefined, automated transactions
                      that occur on specific dates.
                      <br />
                      <br />
                      PCX only allows withdrawals when the payer has given
                      sufficient authorization.
                      <br />
                      <br />
                      By customizing UPCX's scheduled payment, it is possible to
                      create various patterns of automatic payment and automatic
                      withdrawal services.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={SCHEDULE_PAYMENT}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
