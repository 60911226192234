import SHARDING from "../../asset/images/technology/sharding.png";

export const Sharding = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Sharding
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      In addition to adopting Graphene technology, UPCX also
                      uses sharding to operate the blockchain even more stable.
                      <br />
                      <br />
                      UPCX uses sharding to improve the ability to handle large
                      amounts of data and requests when the blockchain is scaled
                      up by adding servers and nodes as the volume of data
                      grows.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={SHARDING} alt="split Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
