import CONSENSUS from "../../asset/images/technology/consensus.png";

export const Consensus = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={CONSENSUS}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Consensus Algorithm
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      UPCX uses a hybrid consensus algorithm, combining
                      Delegated Proof of Stake (DPoS) and Byzantine Fault
                      Tolerance (BFT) to improve security and reliability.
                      <br />
                      <br />
                      By using a hybrid consensus algorithm of BFT and DPoS,
                      UPCX achieves a high throughput and a block confirmation
                      time (Settlement Finality) of 1 second.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
