import QUANTUM from "../../asset/images/technology/post_quantum.png";

export const Quantum = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={QUANTUM} alt="split Images" />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Post Quantum Computing
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      The era of quantum computing is undoubtedly upon us. In
                      order to maintain the security and integrity of
                      blockchain-based systems in the era of quantum computing,
                      it is essential to integrate post-quantum cryptography
                      into blockchain technology.
                      <br />
                      <br />
                      UPCX will first implement its own post-quantum
                      cryptography (UPCX-S) for blockchain wallet key generation
                      and verification based on the Ring-LWE (Ring Learning With
                      Error) problem.
                      <br />
                      <br />
                      Cryptographic research over the past 20 years has
                      confirmed that the Ring-LWE problem is quantum
                      computer-resistant.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
