import { EcosystemOverview } from "../component/Ecosystem/EcosystemOverview";
import { Highspeed } from "../component/Ecosystem/Highspeed";

export const Ecosystem = () => {
  return (
    <>
      <EcosystemOverview />
      <Highspeed />
    </>
  );
};
