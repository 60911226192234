import MPA_DESC from "../../asset/images/token/mpa_desc.png";

export const MPA = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      MPA (Market Pegged Asset)
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Market Pegged Asset (MPA) is a certain kind of
                      cryptocurrency that is specifically designed to maintain a
                      stable value by pegging it to an underlying asset or
                      basket of assets, such as fiat currencies, commodities, or
                      other cryptocurrencies.
                      <br />
                      <br />
                      MPA aims to reduce price volatility and to offer a stable,
                      unifying currency for transactions, trading, and
                      investments.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={MPA_DESC} alt="split Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
