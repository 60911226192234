import ESCROW from "../../asset/images/smartcontract/escrow.png";

export const Escrow = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={ESCROW} alt="split Images" />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Non-custodial Escrow
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      With UPCX's non-custodial escrow, funds and assets are
                      locked in the wallet of the user (buyer) when goods or
                      services are purchased. Since there is no need to send
                      funds or assets to another address, such as a custodian,
                      the service can be used safely.
                      <br />
                      <br />
                      UPCX's non-custodial escrow can be integrated with UPCX's
                      scheduled payments to create a simple financial smart
                      contract that can serve as a Letter of Credit (L/C).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
