import React from "react";
import { Link } from "react-router-dom";

type Props = {
  data: any[];
};

const SingleFooter = ({ data }: Props) => {
  return (
    <>
      {data.map((item, innerIndex) => (
        <div className="col-lg-2 col-md-5 col-sm-5 col-5 ms-3" key={innerIndex}>
          <div className="rainbow-footer-widget">
            <div className={`widget-menu-${item.top ? "top" : "bottom"}`}>
              <h4 className="title">{item.title}</h4>
              <div className="inner">
                <ul className="footer-link link-hover">
                  {item.innerItem.map((subItem: any, subIndex: number) => (
                    <li key={subIndex}>
                      <Link
                        to={subItem.link}
                        target={subItem.external ? "_blank" : "_self"}
                      >
                        {subItem.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SingleFooter;
