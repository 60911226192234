import HIGHSPEED from "../../asset/images/ecosystem/highspeed.png";

export const Highspeed = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Highspeed Blockchain
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      UPCX is built with a hybrid consensus algorithm that
                      combines DPoS and BFT (Byzantine Fault Tolerance).
                      <br />
                      <br />
                      Compared to other consensus mechanisms like for example
                      the Nakamoto consensus that is used in Bitcoin, BFT-based
                      consensus algorithms typically enable quick settlement
                      finality of transactions, as they require fewer rounds of
                      communication to reach consensus.
                      <br />
                      <br />
                      By combining this with DPoS, a blockchain network with
                      even faster transaction confirmation and even higher
                      throughput can be achieved.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={HIGHSPEED}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
