"use client";

import React, { useEffect } from "react";
import sal from "sal.js";

import { BlockchainItems } from "./BlockchainItems";

const BlockchainCards = () => {
  useEffect(() => {
    sal();
  }, []);
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap mt--100 mb--200">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h2
                  className="title"
                  data-sal="slide-up"
                  data-sal-duration="400"
                  data-sal-delay="200"
                >
                  Blockchain
                </h2>
                <h4
                  data-sal="slide-up"
                  data-sal-duration="400"
                  data-sal-delay="200"
                >
                  Beyond Payments and into the Binancial
                </h4>
                <p
                  className="description"
                  data-sal="slide-up"
                  data-sal-duration="400"
                  data-sal-delay="300"
                >
                  By making full use of blockchain technology, UPCX increases
                  the convenience of crypto assets and facilities their
                  <br />
                  compatibility and relation to real world assets.
                  <br />
                  In addition, we are working to create and environment in which
                  everyone can utilize the benefits of blockchains with <br />
                  ease, convenience and security, such as smart contracts,
                  digital asset creation and management.
                </p>
              </div>
            </div>
          </div>
          <div className="row row--15 service-wrapper">
            <BlockchainItems />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockchainCards;
