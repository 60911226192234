import { MPA } from "../component/Token/MPA";
import { NFA } from "../component/Token/NFA";
import { UIA } from "../component/Token/UIA";
import { UPC } from "../component/Token/UPC";

export const Token = () => {
  return (
    <>
      <UPC />
      <UIA />
      <MPA />
      <NFA />
    </>
  );
};
