import NFA_DESC from "../../asset/images/token/nfa.png";

export const NFA = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={NFA_DESC} alt="split Images" />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      NFA (Non-Fungible Asset)
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      A Non-Fungible Asset (NFA) is a unique digital asset or
                      identifier that represents ownership of an original,
                      one-of-a-kind item or content.
                      <br />
                      <br />
                      NFAs allow for transparent, decentralized, and secure
                      tracking of ownership and transaction history.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
