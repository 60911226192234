import React from "react";
import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";
import { Body } from "./Body";

import "bootstrap/scss/bootstrap.scss";

// ========= Plugins CSS START =========
import "./asset/css/plugins/feature.css";
import "./asset/css/plugins/animation.css";
import "./asset/css/plugins/fontawesome-all.min.css";
// ========= Plugins CSS END =========

import "./asset/scss/style.scss";

import { AppProvider } from "./provider/AppProvider";

function App() {
  return (
    <>
      <BrowserRouter>
        <AppProvider>
          <Body />
        </AppProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
