import { Bridge } from "../component/Technology/Bridge";
import { Consensus } from "../component/Technology/Consensus";
import { Graphene } from "../component/Technology/Graphene";
import { Quantum } from "../component/Technology/Quantum";
import { Sharding } from "../component/Technology/Sharding";

export const Technology = () => {
  return (
    <>
      <Graphene />
      <Consensus />
      <Sharding />
      <Quantum />
      <Bridge />
    </>
  );
};
