import GRAPHENE from "../../asset/images/technology/graphene.png";

export const Graphene = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Graphene
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Graphene is designed to be highly scalable and efficient,
                      capable of processing thousands to millions of
                      transactions per second. It also has many advantages over
                      traditional blockchains, such as real-time transactions,
                      low latency, high throughput, and scalability.
                      <br />
                      <br />
                      UPCX is using a customized version of Graphene as a base
                      and will create an improved version of it that is even
                      more suitable for payments and financial applications.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={GRAPHENE} alt="split Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
