import { useEffect } from "react";
import Sal from "sal.js";
import RoadmapData from "../../data/roadmap.json";
import Breadcrumb from "../Breadcrumb";

import TeamData from "../../data/team.json";

export const Team = () => {
  useEffect(() => {
    Sal();
  }, []);
  return (
    <>
      <Breadcrumb
        title="Our Dedicated Team"
        text="Always ready our team to help you"
      />
      <div className="roadmap-section rainbow-section-gap-big rainbow-section-gapBottom">
        <div className="container">
          <div className="row row--15 mt_dec--30">
            {TeamData &&
              TeamData.team.map((data, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                  key={index}
                >
                  <div className="team">
                    <div className="thumbnail">
                      <img src={data.img} alt="Team Images" />
                    </div>
                    <div className="content">
                      <h4 className="title">{data.name}</h4>
                      <p className="designation">{data.profission}</p>
                    </div>
                    <ul className="social-icon">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
