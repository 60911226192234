"use client";

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import sal from "sal.js";

const BLOCKCHAIN_ITEMS = [
  {
    title: "Market Pegged Assets(MPA)",
    desc: "By backing up more than 100% of the value, UPCX allows the issuance of 'Stable Coins' that are collateralized by traditional real world assets such as USD, EUR, and gold.",
    delay: 100,
  },
  {
    title: "User Issued Assets(UIA)",
    desc: "By backing up more than 100% of the value, UPCX allows the issuance of 'Stable Coins' that are collateralized by traditional real world assets such as USD, EUR, and gold.",
    delay: 100,
  },
  {
    title: "Non Fungible Assets(NFAs)",
    desc: "UPCX allows the issuance of NFTs. We work on solving current issues in the NFT distribution market and therefore support producers of digital art and other digital items.",
    delay: 100,
  },
  {
    title: "Decentralized Exchange",
    desc: "UPCX's decentralized finance (DeFi) enables autonomous financial transactions that do not require a specific administrator. It allows not just asset exchanges, but also the development of various decentralized financial products.",
    delay: 100,
  },
  {
    title: "UPCX Price Oracle",
    desc: "UPCX's Price Oracle makes it possible to integrate the digital domain of blockchain and the real-world domain of financial data, which has not been possible until now.",
    delay: 100,
  },
  {
    title: "Cross-Chain Bridge",
    desc: "UPCX will implement cross-chain bridges to achieve interoperability with other blockchains, thereby improving the usability of UPCX while leveraging eatch other's strengths and capabilities.",
    delay: 100,
  },
];

export const BlockchainItems = () => {
  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      (bgflashlight as any).onmousemove = function (e: any) {
        let x = e.pageX - (bgflashlight as any).offsetLeft;
        let y = e.pageY - (bgflashlight as any).offsetTop;

        (bgflashlight as any).style.setProperty("--x", x + "px");
        (bgflashlight as any).style.setProperty("--y", y + "px");
      };
    });
  }, []);

  return (
    <>
      {BLOCKCHAIN_ITEMS.map((data, index) => (
        <div
          className="col-lg-4 col-md-6 col-sm-6 col-12"
          data-sal="slide-up"
          data-sal-duration="700"
          data-sal-delay={`${data.delay}`}
          key={index}
        >
          <div className="service service__style--1 bg-color-blackest radius mt--25 text-center rbt-border-none variation-4 bg-flashlight min-height-400 bg-flashlight">
            <div className="content gradient-background">
              <h4 className="title w-600">
                <Link to="#">{data.title}</Link>
              </h4>
              <p className="description b1 color-gray mb--0">{data.desc}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
