import ECOSYSTEM from "../../asset/images/ecosystem/ecosystem.png";

export const EcosystemOverview = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={ECOSYSTEM}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      EcoSystem Overview
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      UPCX is an Ethereum Virtual Machine (EVM) compatible
                      Delegated Proof of Stake (DPoS) blockchain, that is
                      specifically designed for payment and financial
                      transaction purposes, solving many problems that arise
                      with other blockchains.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
