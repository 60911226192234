import REFERRAL from "../../asset/images/functions/referrals.png";

export const Referral = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Referral Program
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      The UPCX Referral Program allows users to earn rewards by
                      referring others to UPCX. Referral rewards are typically
                      paid out in UPC, UPCX's native cryptocurrency.
                      <br />
                      <br />
                      This program aims to encourage users to attract more
                      people to UPCX, thus increasing the adaptation and usage
                      of UPCX.
                      <br />
                      <br />
                      Referral rewards are based on a percentage of transaction
                      fees incurred by the referred user.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={REFERRAL} alt="split Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
