import MARKET_TRNEDS from "../../asset/images/vision_team/market_trends.jpg";
import DISTRIBUTION from "../../asset/images/vision_team/distributions.jpg";

export const MarketTrends = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h2 className="title w-600 mb--20">Market Trends</h2>
              </div>
            </div>
          </div>

          <div className="row row--15 service-wrapper">
            <div className="thumbnail">
              <img className="radius" src={MARKET_TRNEDS} alt="split Images" />
            </div>
            <p className="description b1">
              According to 360iResearch, the fintech blockchain market, is
              currently valued at USD 5.5 billion, and is expected to grow at an
              annual rate of 36% to become a USD 64.7 billion market by 2030.
            </p>
            <p className="description b1">
              Looking at a breakdown of the market, UPCX's core domains of
              “Payment, Clearing & Settlement” and “Cross-Border Payment and
              Exchange & Remittance” are expected to maintain the biggest share
              of around 57% until 2030. This translates in an expected
              significant growth in the UPCX related fintech blockchain market,
              reaching USD 36.8 billion in 2030 up from USD 3.2 billion in 2022.
            </p>
            <div className="thumbnail">
              <img className="radius" src={DISTRIBUTION} alt="split Images" />
            </div>
            <p className="description b1">
              The expansion of the fintech blockchain market is thought to be
              mainly driven by the private sector, but recent movements in the
              public sector like the issuance of CBDC (Central Bank Digital
              Currency) are also noteworthy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
