import { Link } from "react-router-dom";

const Connect = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap">
        <div className="container">
          <div className="rbt-daynamic-page-content center-width">
            <div className="banner-area">
              <div className="settings-area">
                <h3 className="title">Connect with UPCX</h3>
              </div>
            </div>
          </div>
          <div className="rbt-daynamic-page-content ">
            <div className="content-page pb--50 pl--200 ml--100">
              <div className="chat-box-list">
                <div className="content">
                  <p>
                    Android Wallet:{" "}
                    <Link
                      to="#"
                      className="ml--10"
                      style={{ textDecoration: "underline" }}
                    >
                      https://wallet.upcx.org
                    </Link>
                  </p>
                  <p>
                    Mobile Wallet:{" "}
                    <Link
                      to="#"
                      className="ml--10"
                      style={{ textDecoration: "underline" }}
                    >
                      https://wallet.upcx.org
                    </Link>
                  </p>
                  <p>
                    Extension Wallet:{" "}
                    <Link
                      to="#"
                      className="ml--10"
                      style={{ textDecoration: "underline" }}
                    >
                      https://wallet.upcx.org
                    </Link>
                  </p>
                  <p>
                    Web Wallet:{" "}
                    <Link
                      to="#"
                      className="ml--10"
                      style={{ textDecoration: "underline" }}
                    >
                      https://wallet.upcx.org
                    </Link>
                  </p>
                  <p>
                    BlockExplorer:{" "}
                    <Link
                      to="#"
                      className="ml--10"
                      style={{ textDecoration: "underline" }}
                    >
                      https://explorer.upcx.org
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Connect;
