import { Hardware } from "../component/Products/Hardware";
import { POS } from "../component/Products/POS";
import { Wallet } from "../component/Products/Wallet";

export const Products = () => {
  return (
    <>
      <Wallet />
      <POS />
      <Hardware />
    </>
  );
};
