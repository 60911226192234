import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ_DATA = [
  {
    title: "What is the blockchain?",
    desc: "A blockchain is a decentralized digital ledger that records transactions across many computers in such a way that the registered transactions cannot be altered retroactively.",
    isExpand: false,
  },
  {
    title: "Which consensus algorithms does UPCX blockchain use?",
    desc: "UPCX blockchain uses hybrid consensus algorithm including Delegated Proof of Stake(DPoS) and Delegated Byzantine Fault Tolerance(DBFT).",
    isExpand: false,
  },
  {
    title: "How do I use UPCX blockchain?",
    desc: "The basic concept of the UPCX wallet is that anyone can use it easily and intuitively, just like the wallet apps of conventional mobile payment services. UPCX blockchain has Mobile Wallet(Android and iOS), Extension Wallet and Web Wallet.",
    isExpand: false,
  },
  {
    title:
      "Does UPCX blockchain support Virtual Machine for the smart contract?",
    desc: "Yes. UPCX blockchain supports its original VM and EVM(Ethereum Virtual Machine) for the smart contract developers to interact with UPCX blockchain easily.",
    isExpand: false,
  },
  {
    title: "What is the gas fee?",
    desc: "Gas fees are payments made to compensate for the computational energy required to process transactions and execute smart contracts on the UPCX blockchain.",
    isExpand: false,
  },
  {
    title: "What types of assets does UPCX blockchain support?",
    desc: "UPCX blockchain supports various types of assets including UPC(Native asset), UIA(User Issued Asset), MPA(Market Pegged Asset) and NFA(Non-Fungible Asset).",
    isExpand: false,
  },
];

const FAQItems = () => {
  const [showItems, setShowItems] = useState<boolean[]>(Array(6).fill(false));

  const handleItem = (index: number) => {
    const tempShowItems = [...showItems];
    tempShowItems[index] = !tempShowItems[index];
    setShowItems(tempShowItems);
  };

  return (
    <>
      <div className="rainbow-accordion-style  accordion">
        <div className="accordion" id="accordionExamplea">
          {FAQ_DATA.map((data, index) => (
            // <Accordion
            //   className="accordion-item card bg-flashlight"
            //   key={index}
            // >
            //   <AccordionSummary
            //     expandIcon={<ExpandMoreIcon />}
            //     aria-controls="panel1-content"
            //     id="panel1-header"
            //     className="accordion-header card-header"
            //   >
            //     {data.title}
            //   </AccordionSummary>
            //   <AccordionDetails>
            //     <div className="accordion-body card-body">{data.desc}</div>
            //   </AccordionDetails>
            // </Accordion>
            <div className="accordion-item card bg-flashlight" key={index}>
              <h2
                className="accordion-header card-header"
                id={`heading${index + 1}`}
              >
                <button
                  className={`accordion-button ${
                    !showItems[index] ? "collapsed" : ""
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index + 1}`}
                  aria-expanded={data.isExpand ? "true" : "false"}
                  aria-controls={`collapse${index + 1}`}
                  onClick={() => handleItem(index)}
                >
                  {data.title}
                </button>
              </h2>
              <div
                id={`collapse${index + 1}`}
                className={`accordion-collapse collapse ${
                  showItems[index] ? "show" : ""
                }`}
                aria-labelledby={`heading${index + 1}`}
                data-bs-parent="#accordionExamplea"
              >
                <div className="accordion-body card-body">{data.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQItems;
