import React, { useState } from "react";

const ContactForm = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleSendEmail = () => {
    const recipient = "tech-admin@upcx.org"; // Replace with the recipient's email address
    const body = `Name: ${name}\n\nPhone: ${phone}\n\nMessage: ${message}`; // Encoding to ensure proper formatting

    // Construct the mailto link
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the mailto link, which opens the user's default email client
    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="form-group">
        <input
          type="text"
          name="contact-name"
          id="contact-name"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="contact-phone"
          id="contact-phone"
          placeholder="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="Your Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>

      <div className="form-group">
        <textarea
          name="contact-message"
          id="contact-message"
          placeholder="Your Message"
          onChange={(e) => setMessage(e.target.value)}
        >
          {message}
        </textarea>
      </div>

      <div className="form-group">
        <button
          name="submit"
          id="submit"
          className="btn-default btn-large rainbow-btn"
          onClick={handleSendEmail}
        >
          <span>Submit Now</span>
        </button>
      </div>
    </>
  );
};

export default ContactForm;
