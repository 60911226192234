import React from "react";

const LegalDisclaimer = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap">
        <div className="container">
          <div className="rbt-daynamic-page-content center-width">
            <div className="banner-area">
              <div className="settings-area">
                <h3 className="title">Legal Disclaimer</h3>
              </div>
            </div>
            <div className="content-page ">
              <div className="chat-box-list">
                <div className="content">
                  <p>
                    The following legal disclaimer applies to the sale,
                    purchase, and use of the UPCX crypto asset UPC (hereinafter
                    referred to as "UPC"), and is intended without limitations
                    for all potential participants, investors, and users of UPC,
                    regardless of their country of residence or jurisication. By
                    possessing UPC, you agree to be bound by the terms of this
                    disclaimer. Therefore, if you wish to purchase or hold UPC,
                    or already do so, please carefully review and understand
                    this disclaimer, and if you do not agree with its terms and
                    conditions, do not purchase or hold any UPC. Please note
                    that this document, the UPCX whitepaper and the UPCX
                    homepage, do not provide legal or financial advice regarding
                    the purchase of UPC and the trading in UPC, and therefore we
                    strongly recommend seeking advice from professional bodies
                    that deal with the laws and regulations in your jurisdiction
                    before purchasing or trading UPC.
                  </p>
                </div>
              </div>
            </div>
            <div className="content-page pb--50">
              <div className="chat-box-list">
                <div className="content">
                  <ol className="ml--20">
                    <li>
                      UPC ownership is permitted to individuals and corporations
                      in all countries. However, UPC holders are responsible for
                      ensuring that they comply with the laws and regulations in
                      their own jurisdiction when purchasing or trading UPC.
                    </li>
                    <li>
                      UPC holders should be aware that the regulatory
                      environment governing cryptocurrencies and digital assets
                      may change, and new laws and regulations may be enacted in
                      the future. As such, UPC holders must prepare to comply
                      with any applicable future laws and regulations regarding
                      UPC.
                    </li>
                    <li>
                      This document, the UPCX whitepaper and the UPCX homepage
                      is for informational purposes only. Unless otherwise
                      specified, the products, services and technologies
                      described are under development, and we do not guarantee
                      their outcome, final use and success.
                    </li>
                    <li>
                      The assumptions, expressions, conclusions, possibilities,
                      business strategies, future business goals, roadmaps, and
                      schedules contained in this material, the UPCX whitepaper
                      and the UPCX homepage, are future predictions or
                      projections that involve uncertainties and potential
                      risks. Actual results may differ strongly from those
                      expressed or implied.
                    </li>
                    <li>
                      UPCX, its directors, officers, employees, advisors, or
                      affiliated companies do not guarantee or assume any
                      liability arising from, or related to, the accuracy,
                      reliability, or completeness of the information contained
                      in this material, the UPCX whitepaper and the UPCX
                      homepage. UPCX shall not, in any jurisdiction and to the
                      fullest extent permitted by applicable laws, be liable for
                      any indirect, special, incidental, consequential or other
                      losses (including, but not limited to, loss of revenue,
                      loss of income or profits, and loss of data) arising out
                      of the use or related to the use of this document, the
                      UPCX homepage and UPCX whitepaper.
                    </li>
                    <li>
                      UPCX assumes no obligation or responsibility to update or
                      change forward-looking statements due to new information,
                      subsequent events, or other circumstances.
                    </li>
                    <li>
                      This legal disclaimer, as well as the UPCX homepage and
                      UPCX whitepaper, may be updated or changed at any time, in
                      which case the latest version of this document will take
                      precedence over previous versions. UPCX is not obligated
                      to notify customers at the time of change, and about the
                      change itself.
                    </li>
                    <li>
                      While UPCX has made every effort to ensure that all data
                      submitted in this document, as well as in the UPCX
                      whitepaper and on the UPCX homepage, is accurate and
                      up-to-date at the time of distribution and release, it
                      does not replace seeking independent third-party opinions.
                    </li>

                    <li>
                      This document, the UPCX whitepaper and the UPCX homepage
                      as well as related materials may be translated into
                      languages other than English. In the event of any
                      discrepancy or inconsistency between the English version
                      and version in other foreign languages, the English
                      version shall prevail.
                    </li>

                    <li>
                      The contents of this document have not been approved or
                      reviewed by any regulatory authority. Furthermore, the
                      publication and distribution of this material, as well as
                      of the UPCX whitepaper and UPCX homepage, does not imply
                      that relevant laws and regulations have been complied
                      with. As a result, no action has been taken, and no action
                      is currently planned, in relation to regulatory
                      requirements or rules in any jurisdiction.
                    </li>
                  </ol>
                  <p>
                    As such, UPC Holders acknowledge and agree to the terms set
                    forth in this Legal Disclaimer and explicitly accept all
                    associated risks, liability and potential consequences of
                    their involvement with UPC and UPCX.
                  </p>
                  <p>
                    <div className="warning-wrapper">
                      <h5 className="mb--0 d-flex mb--5">
                        <div className="icon mr--5 d-flex">
                          <i
                            className={`feather-alert-triangle`}
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          ></i>
                        </div>
                        warning
                      </h5>
                      Please note that this document and the UPCX whitepaper, or
                      any part or copy thereof, shall not be published or
                      transmitted to countries where distribution or
                      dissemination of this document is prohibited or
                      restricted.
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalDisclaimer;
