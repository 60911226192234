import SUPER_APPLICATION_1 from "../../asset/images/super_application/super_application1.png";
import SUPER_APPLICATION_2 from "../../asset/images/super_application/super_application2.png";

export const SuperApplication = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="section-title text-center"
                    data-sal="slide-up"
                    data-sal-duration="700"
                    data-sal-delay="100"
                  >
                    <h2 className="title w-600 mb--20">Super Application</h2>
                  </div>
                </div>
              </div>
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={SUPER_APPLICATION_1}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      UPCX's mobile app integrates a variety of services that
                      cover not just payments but many aspects of daily life.
                      <br />
                      <br />
                      For example, you can make restaurant reservations,
                      purchase movie tickets, call taxis, pay utility bills and
                      manage and operate assets all from a single app.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row g-0 radius-10 align-items-center mt-5">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      By using the UPCX wallet in the super app you can quickly
                      complete payments simply by scanning a QR code or tapping
                      your smartphone on an NFC reader.
                      <br />
                      <br />
                      UPCX's super app does not stop at bundling together
                      various services. Services from other apps can be
                      integrated through UPCX, enabling users to protect their
                      privacy while making effective use of their extensive
                      activity history, allowing them to enjoy a new lifestyle.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={SUPER_APPLICATION_2}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
