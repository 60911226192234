import { PropsWithChildren, useEffect } from "react";
import BackToTop from "../component/BackToTop";
import { Footer } from "./Footer";
import Header from "./Header";
import sal from "sal.js";
import { useLocation } from "react-router-dom";
import PopupMobileMenu from "./PopupMobileMenu";
import { useAppContext } from "../hook/useAppContext";

export const WrappedLayout: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const location = useLocation();

  const { setActiveMobileMenu } = useAppContext();

  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      (bgflashlight as any).onmousemove = function (e: any) {
        let x = e.pageX - (bgflashlight as any).offsetLeft;
        let y = e.pageY - (bgflashlight as any).offsetTop;

        (bgflashlight as any).style.setProperty("--x", x + "px");
        (bgflashlight as any).style.setProperty("--y", y + "px");
      };
    });

    window.scrollTo(0, 0);

    setActiveMobileMenu(true);
  }, [location]);

  return (
    <main className="page-wrapper">
      <Header
        headerTransparent="header-not-transparent"
        headerSticky="header-sticky"
        btnClass="btn-small round"
      />
      <PopupMobileMenu />
      {children}
      <Footer />
    </main>
  );
};
