import React from "react";

const CookiePolicy = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap">
        <div className="container">
          <div className="rbt-daynamic-page-content center-width">
            <div className="banner-area">
              <div className="settings-area">
                <h3 className="title">Cookie Policy</h3>
              </div>
            </div>

            <div className="content-page pb--50">
              <div className="chat-box-list">
                <div className="content">
                  <p>
                    UPCX automatically acquires cookies in order to improve the
                    users' convenience and experience of the websites and other
                    digital services it operates.
                  </p>
                  <p>
                    <div className="tip-wrapper">
                      <h5 className="mb--0 d-flex mb--5">
                        <div className="icon mr--5 d-flex">
                          <i
                            className={`feather-info`}
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          ></i>
                        </div>
                        TIP
                      </h5>
                      Cookies are information, such as website browsing records,
                      that are sent from a server and stored on the user's
                      device as a small data file. Cookies enable users to
                      receive customized information and allow the efficient
                      provision of services. Cookies do not contain information
                      that identifies individual users.
                    </div>
                  </p>
                  <p>
                    Users can choose to accept or disable cookies issued by our
                    website or third-party companies. If you disable cookies,
                    you may not be able to properly use all or part of the
                    services on this site.
                  </p>
                  <h3>About cookie types</h3>
                  <h4>Strictly necessary cookies</h4>
                  <p>
                    These cookies are necessary for the website to function
                    properly and should not be disabled on your system. You can
                    set your browser to disable these cookies, but some parts of
                    the site will no longer function correctly. This cookie does
                    not store any personally identifiable information.
                  </p>
                  <h4>Performance cookies</h4>
                  <p>
                    These cookies help us to improve the performance of our site
                    by, for example, measuring the number of visitors. It helps
                    us to see which pages are the most popular and understand
                    how visitors move around and use our site. This cookie does
                    not store any personally identifiable information.
                  </p>
                  <h4>Functional cookies</h4>
                  <p>
                    These cookies allow us to enhance and personalize the
                    functionalities of our website. If you disable these
                    cookies, some or all of our Services may not function
                    properly. This cookie does not store any personally
                    identifiable information.
                  </p>
                  <h4>How to disable cookies in your browsers settings</h4>
                  <p>
                    You can set your browser to refuse cookies or to display a
                    warning when you are sent cookies. For information on how to
                    configure your browser, please check the help entry in your
                    browser.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
