import { MarketTrends } from "../component/VisionTeam/MarketTrends";
import { RoadMap } from "../component/VisionTeam/RoadMap";
import { Team } from "../component/VisionTeam/Team";

export const VisionTeam = () => {
  return (
    <>
      <MarketTrends />
      <RoadMap />
      <Team />
    </>
  );
};
