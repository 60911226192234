import POS_DESC from "../../asset/images/products/pos.png";

export const POS = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={POS_DESC} alt="split Images" />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      UPCX POS App
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      UPCX will offer a POS app available for both Android OS
                      and iOS devices to streamline payment processing and
                      inventory management.
                      <br />
                      <br />
                      The UPCX POS app is designed for businesses of all sizes
                      and industries, offering functions such as product
                      registration and inventory management while allowing
                      seamless payment processing with UIA and MPA that are
                      issued on UPCX.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
