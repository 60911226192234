import lightImg from "../asset/images/separator/separator-bottom.svg";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  text: any;
};

const Breadcrumb = ({ title, text }: Props) => {
  return (
    <>
      <div className="main-content">
        <div className="breadcrumb-area breadcarumb-style1 pt--100 pb--60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner text-center">
                  <h1 className="title theme-gradient h2">{title}</h1>
                  <p className="rainbow-breadcrumb-item active">{text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chatenai-separator">
          <img
            className={`w-100 separator-light"
            `}
            src={lightImg}
            alt="separator"
          />
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
