"use client";

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import sal from "sal.js";

const PAYMENT_ITEMS = [
  {
    title: "Named Accounts ",
    desc: "Choose your UPCX account address freely without compromising security, just like an e-mail account.",
    icon: "user",
    delay: 100,
  },
  {
    title: "Push & Pull Payments",
    desc: "Use all familiar payment methods, including NFC, QR code & barcode scanning and scheduled payments.",
    icon: "shuffle",
    delay: 100,
  },
  {
    title: "Non-Custodial Escrow and Multi-Signature Payment",
    desc: "Secure high-value transactions by non-custodial escrow with optional arbitrator and multi-signature permission.",
    icon: "users",
    delay: 100,
  },
  {
    title: "Instant Cross-Currency Payment",
    desc: "Pay with any supported asset, including stable coins, with direct instant conversion via the UPCX decentralized exchange with price oracle.",
    icon: "credit-card",
    delay: 100,
  },
  {
    title: "Sophisticated Privacy Settings",
    desc: "UPCX allows for anonymized accounts and transactions while keeping in line with KYC and AML requirements.",
    icon: "lock",
    delay: 100,
  },
  {
    title: "Offline Payment",
    desc: "Make payments even when you are offline and and cannot access the internet.",
    icon: "wifi-off",
    delay: 100,
  },
];

export const PaymentItems = () => {
  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      (bgflashlight as any).onmousemove = function (e: any) {
        let x = e.pageX - (bgflashlight as any).offsetLeft;
        let y = e.pageY - (bgflashlight as any).offsetTop;

        (bgflashlight as any).style.setProperty("--x", x + "px");
        (bgflashlight as any).style.setProperty("--y", y + "px");
      };
    });
  }, []);

  return (
    <>
      {PAYMENT_ITEMS.map((data, index) => (
        <div
          className="col-lg-4 col-md-6 col-sm-6 col-12 "
          data-sal="slide-up"
          data-sal-duration="700"
          data-sal-delay={`${data.delay}`}
          key={index}
        >
          <div className="service service__style--1 bg-color-blackest radius mt--25 text-center rbt-border-none variation-4 bg-flashlight min-height-400">
            <div className="icon">
              <i className={`feather-${data.icon}`}></i>
            </div>
            <div className="content">
              <h4 className="title w-600">
                <Link to="#">{data.title}</Link>
              </h4>
              <p className="description b1 color-gray mb--0">{data.desc}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
