import { useEffect } from "react";
import { Link } from "react-router-dom";
import "venobox/dist/venobox.min.css";
import SEPARATOR from "../../asset/images/separator/separator-top.svg";
import HOME_VIDEO from "../../asset/images/home/home_video.png";
import { Separator } from "../Separator";

export const Hero = () => {
  useEffect(() => {
    // @ts-ignore
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
        autoplay: true,
      });
    });
  }, []);
  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="inner text-center mt--50 pt--100">
                <h2 className="title display-one">
                  Beyond Payments <br />
                  <span
                    className="theme-gradient mt--30"
                    style={{ fontSize: "100px" }}
                  >
                    UPCX
                  </span>
                </h2>
                <p className="b1 desc-text col-lg-8 mx-auto mt--60">
                  UPCX is the open-source smart-contract enabled decentralized
                  payment network that will make blockchain payments available
                  and useable to everyone.
                </p>
                <div className="button-group mt--100 text-center mb--150">
                  <Link
                    className="btn-default btn-large"
                    to="https://upcx.io/whitepaper/"
                    target="_blank"
                  >
                    Read WhitePaper 1.0
                  </Link>
                  <Link
                    className="btn-default btn-large btn-border "
                    to="/connect"
                  >
                    Connect with UPCX
                  </Link>
                </div>
              </div>
            </div>
            <Separator top={false} />

            <div className="col-lg-12">
              <div className="inner text-center mt--60">
                <h2 className="title display-one">
                  Introducing UPCX <br />
                </h2>
                <h3>Welcome to the Binancial World</h3>
                <p className="b1 desc-text col-lg-8 mx-auto mt--10">
                  UPCX is creating an easier to understand, more convenient,{" "}
                  <br />
                  more secure global standard payment platform for the masses.
                </p>
              </div>
            </div>
            <div className="col-lg-10 col-xl-10 order-1 order-lg-2 mt--50">
              <div className="frame-image frame-image-bottom bg-flashlight video-popup icon-center">
                <img src={HOME_VIDEO} alt="Banner Images" />
                <div className="video-icon">
                  <Link
                    className="btn-default rounded-player popup-video border bg-white-dropshadow"
                    to="/video/1920-1080px_2Mbps-023145d3e1493f00f80e3e216612ad2b.mp4"
                    data-vbtype="video"
                  >
                    <span>
                      <i className="feather-play"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chatenai-separator has-position-bottom">
          <img className="w-100" src={SEPARATOR} alt="" />
        </div>
      </div>
    </>
  );
};
