import { useContext } from "react";
import { AppContext, AppContextValue } from "../context/AppContext";

export const useAppContext = () => {
  const context = useContext(AppContext) as AppContextValue;
  if (!context) {
    throw new Error(
      "Make sure to only call useAppContext within a  <AppProvider>"
    );
  }
  return context;
};
