import { PropsWithChildren, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [mobile, setMobile] = useState<boolean>(true);
  const [rightBar, setRightBar] = useState<boolean>(true);
  const [toggleTop, setToggle] = useState<boolean>(true);
  const [toggleAuth, setToggleAuth] = useState<boolean>(true);
  const [showItem, setShowItem] = useState<boolean>(true);
  const [activeMobileMenu, setActiveMobileMenu] = useState<boolean>(true);

  const checkScreenSize = () => {
    if (window.innerWidth < 1200) {
      setMobile(false);
      setRightBar(false);
    } else {
      setMobile(true);
      setRightBar(true);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        mobile,
        setMobile,
        showItem,
        setShowItem,
        activeMobileMenu,
        setActiveMobileMenu,
        toggleTop,
        setToggle,
        toggleAuth,
        setToggleAuth,
        rightBar,
        setRightBar,
        shouldCollapseLeftbar: !mobile,
        shouldCollapseRightbar: !rightBar,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
