"use client";

import { PaymentItems } from "./PaymentItems";

const Payment = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap mb--150 mt--100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h2 className="title w-600 mb--20">Payment</h2>
                <h4 className="title w-600 mb--20">
                  Towards a more familiar payment experience
                </h4>
                <p className="description b1">
                  Although Blockchain is expected to be a promising technology
                  in the payment and financial fields, <br />
                  there are still issues that have to be overcome in order for
                  it to be fully accepted and adopted, such as <br />
                  usage fees, time until transaction finality, and general ease
                  of use. <br />
                  UPCX solves these issues and creates a payment system that
                  incorporates the benefits of blockchain <br />
                  technology while maintaining the existing user experience.
                </p>
              </div>
            </div>
          </div>

          <div className="row row--15 service-wrapper">
            <PaymentItems />
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
