import PERMISSION_PAYMENT from "../../asset/images/smartcontract/permission_payment.png";

export const PermissionPayment = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Permission Payments
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Permission Payment is a controlled payment mechanism in
                      which transactions are authorized only after obtaining
                      approval from a predefined set of participants or under
                      specific conditions.
                      <br />
                      <br />
                      UPCX uses permissions consisting of one or more approvers
                      to authenticate tasks such as transferring funds or
                      modifying account.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={PERMISSION_PAYMENT}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
