import React from "react";

import CONTACT_US from "../asset/images/contactus/contact_us.png";
import ContactForm from "../component/ContactForm";

const ContactUs = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap">
        <div className="rbt-daynamic-page-content center-width">
          <div className="rbt-dashboard-content">
            <div className="banner-area">
              <div className="settings-area mb--0">
                <h3 className="title">Contact Us</h3>
                <p className="mb--10">
                  If you have any questions on the UPCX BlockExplorer APIs, ask
                  them here!
                </p>
                <img src={CONTACT_US} />
                <div className="container">
                  <div className="row justify-content-center mt--20">
                    <div className="col-lg-8">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-page pb--50 ">
              <div className="chat-box-list">
                <div className="content">
                  {/* <p>
                    <div className="tip-wrapper">
                      If you have any question on the UPCX Blockchain, contact
                      with tech-admin@upcx.org!
                      <br />
                      Before the contating, Please understand we can only assist
                      with issues directly related with UPCX blockchain
                      services. Before writing in, please be informed that:
                      <br />
                      <ol className="mr--10">
                        <li>
                          Pending Transaction
                          <p>
                            We do not process transactions and are therefore
                            unable to expedite, cancel or replace them. If you
                            are having issues with pending transactions, please
                            refer here.
                          </p>
                        </li>
                        <li>
                          Community Support
                          <p>
                            We will never ask for your privacy, Never send us
                            your privacy. While we try our best to respond in a
                            timely manner, we often have a backlog of inquiries,
                            Please do not spam us.
                          </p>
                        </li>
                        <li>
                          Wallet/Exchange/Project related issues
                          <p>
                            Kindly to your wallet service provider, exchange or
                            project/contract owner for further support as they
                            are in a better position to assist you on the issues
                            related with and from their platforms.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
