import { Link } from "react-router-dom";
import TECHNOLOGY from "../../asset/images/home/technology.jpg";

export const Technology = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap mt--100 mb--100">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="section-title">
                    <h2
                      className="title w-600 mb--20"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Technology
                    </h2>
                    <h4
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                      className="title w-600 mb--20"
                    >
                      Improved Graphene and <br />
                      Hybrid Consensus Algorithm
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      UPCX utilizes a for payments and finance further
                      specialized version of Cryptonomex's Graphene blockchain
                      technology in combination with a Delegated Proof of Stake
                      (DPoS) and Delegated Byzantine Fault Tolerance(DBFT),
                      allowing performance metrics close or better to leading
                      credit-card networks.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={TECHNOLOGY}
                      alt="split Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
