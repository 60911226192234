import { Scalability } from "../component/Solution/Scalability";
import { Useability } from "../component/Solution/Useability";
import { UserExperience } from "../component/Solution/UserExperience";

export const Solution = () => {
  return (
    <>
      <UserExperience />
      <Scalability />
      <Useability />
    </>
  );
};
