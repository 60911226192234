import BRIDGE from "../../asset/images/technology/bridge.png";

export const Bridge = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Cross Chain Bridge
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      UPCX will implement cross-chain bridges to achieve
                      interoperability with other blockchains.
                      <br />
                      <br />
                      UPCX uses the COSMOS protocol to build these cross-chain
                      bridges.
                      <br />
                      <br />
                      It also allows developers to create interconnected
                      applications that span a variety of different blockchains.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img className="radius" src={BRIDGE} alt="split Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
