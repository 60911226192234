import { createContext } from "react";

export interface AppContextValue {
  mobile: boolean;
  rightBar: boolean;
  toggleTop: boolean;
  toggleAuth: boolean;
  showItem: boolean;
  activeMobileMenu: boolean;
  setMobile: (mobile: boolean) => void;
  setRightBar: (rightBar: boolean) => void;
  setToggle: (toggleTop: boolean) => void;
  setToggleAuth: (toggleAuth: boolean) => void;
  setShowItem: (showItem: boolean) => void;
  setActiveMobileMenu: (activeMobileMenu: boolean) => void;
  shouldCollapseLeftbar: boolean;
  shouldCollapseRightbar: boolean;
}

export const AppContext = createContext<null | AppContextValue>(null);
