import { Escrow } from "../component/SmartContract/Escrow";
import { PermissionPayment } from "../component/SmartContract/PermissionPayment";
import { RecurringPayment } from "../component/SmartContract/RecurringPayment";
import { SchedulePayment } from "../component/SmartContract/SchedulePayment";

export const SmartContract = () => {
  return (
    <>
      <SchedulePayment />
      <RecurringPayment />
      <PermissionPayment />
      <Escrow />
    </>
  );
};
