import SCALABILITY from "../../asset/images/solution/scalability.png";

export const Scalability = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="radius"
                      src={SCALABILITY}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h2
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Scalability
                    </h2>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Blockchain technology has long been expected to solve
                      various problems of existing payment methods and should
                      have become an effective solution for next-generation
                      payment systems.
                      <br />
                      <br />
                      However, at the time of writing, the impact on existing
                      payment methods is still limited and widely used practical
                      services have yet to take root.
                      <br />
                      <br />
                      We believe that one of the reasons for this is that many
                      blockchains used in payment-related applications are
                      inefficiently designed and, therefore, inferior in
                      processing power in comparison to existing payment
                      methods, such as credit card and mobile payment systems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
