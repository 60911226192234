"use client";

import HEADER_DATA from "../data/header.json";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../hook/useAppContext";

const Nav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setActiveMobileMenu } = useAppContext();

  const isActive = (href: string) => location.pathname.startsWith(href);

  return (
    <>
      <ul className="mainmenu">
        {HEADER_DATA.navItems.map((item, index) => {
          // return item.children ? (
          //   <li
          //     className="with-megamenu has-menu-child-item position-relative"
          //     key={index}
          //   >
          //     <a
          //       href={item.link}
          //       onClick={() => setShowItem(!showItem)}
          //       className={`${!showItem ? "open" : ""}`}
          //     >
          //       {item.text}
          //     </a>
          //     <div
          //       className={`rainbow-megamenu right-align with-mega-item-2 ${
          //         showItem ? "" : "d-block"
          //       } small`}
          //     >
          //       <div className="wrapper p-0">
          //         <div className="row row--0">
          //           <div className="col-lg-12 single-mega-item">
          //             <h3 className="rbt-short-title">{item.text}</h3>
          //             <ul className="mega-menu-item">
          //               {item.children.map((subMenu, subIndex) => (
          //                 <li key={subIndex}>
          //                   <Link
          //                     to={subMenu.link}
          //                     className={isActive(subMenu.link) ? "active" : ""}
          //                   >
          //                     {subMenu.text}
          //                   </Link>
          //                 </li>
          //               ))}
          //             </ul>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </li>
          // ) : (
          //   <li>
          //     <Link to={item.link}>{item.text}</Link>
          //   </li>
          // );

          return (
            <li>
              <Link to={item.link} target={item.external ? "_blank" : "_self"}>
                {item.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Nav;
